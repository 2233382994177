import '../lib/DrawSVGPlugin.min.js';
import $ from 'jquery';
import {TweenMax, Power2, TimelineLite,TimelineMax} from "gsap";
(function($){
	
	var $slider=$('.slider');
	var $blackopacity=$('.blackopacity');
	var tdelay=7;
	var tfade=1.2;
	var tblack=1;
	var mobile=0;
	var tl=null;
	function slide() {
		
		if($(window).width() < 760 && $(window).width() > 480 ){ mobile=1 }
		else if($(window).width() <= 480){mobile=2} 
	
		//$(".greensock-slider").height($(".greensock-slider").width() * 560/1590);
		TweenMax.set($slider.filter(":gt(0)"), {autoAlpha:0});
		TweenMax.set($slider.filter(":eq(0)"), {autoAlpha:1});
		//TweenMax.set($blackopacity, {autoAlpha:1});
		tl= new TimelineMax({repeat:-1,repeatDelay:0});
		//console.log($slider);
		for(tl.data=0;tl.data<$slider.length;tl.data++) {
			if (tl.data==$slider.length-1) {
				tl.to($slider[0],tfade,{autoAlpha:1},(tl.data+1)*tdelay);
				tl.to($slider[tl.data],tfade,{autoAlpha:0},(tl.data+1)*tdelay);
			} else {
				tl.to($slider[tl.data+1],tfade,{autoAlpha:1},(tl.data+1)*tdelay);
				tl.to($slider[tl.data],tfade,{autoAlpha:0},(tl.data+1)*tdelay);
			}
		
			tl.set(
					$slider[tl.data].nextSibling,
					{css:{'z-index':20}},
					(tl.data+0)*tdelay
				
			);	
			tl.set(
					$slider[tl.data].nextSibling,
					{css:{'z-index':15}},
					(tl.data+1)*tdelay
				
			);	
				
			var arr=[].slice.call($slider[tl.data].nextSibling.children);
			var j=1

			tl.set(
				arr[j],
				{css:{'opacity':0}},
				(tl.data+0)*tdelay
				
			);	
			
			if (mobile === 1) {
				tl.fromTo(
					arr[j],
					.6,
					{css:{'top':'calc(50% - 60px)' }},
					{css:{'top':'calc(50% - 30px)' ,'opacity':1},ease:Power2.easeOut},
					tl.data*tdelay + tfade
				);
			
			} else if (mobile === 2) {
				tl.fromTo(
					arr[j],
					.6,
					{css:{'top':'calc(50% - 80px)' }},
					{css:{'top':'calc(50% - 60px)' ,'opacity':1},ease:Power2.easeOut},
					tl.data*tdelay + tfade
				);
			
			} else {
				tl.fromTo(
					arr[j],
					.6,
					{css:{'top':'calc(50% - 90px)' }},
					{css:{'top':'calc(50% - 0px)' ,'opacity':1},ease:Power2.easeOut},
					tl.data*tdelay + tfade
				);
			}
		
			tl.to(
				arr[j],
				tfade,
				{css:{'opacity':0},ease:Power2.easeOut},
				(tl.data+1)*tdelay - tfade
			);
						
		}
		
		$('#p'+tl.data).click(function() {
			//$(this).data(id);
			console.log($(this));
			tl.data=$(this).data('id');
			console.log(tl.data);
			tl.seek(tl.data*tdelay+.6);
		})
			
	}
		
		
	$('#bnext').click(function() {
		    console.log("before"+tl.data);
			if(tl.data>=4){tl.data=0;}
			else{tl.data=tl.data+1;}
			console.log(tl.data);
			tl.seek(tl.data*tdelay+.6);
		
	});
	$('#bprev').click(function() {
			console.log("before"+tl.data);
			if(tl.data<=0){tl.data=0;}
			else{tl.data=tl.data-1;}
			console.log(tl.data);
			tl.seek(tl.data*tdelay+.6);
		
	});	
	
	slide();
	
	$( window ).resize(function() {
		tl.stop();
		tl.kill();
		tl=null;
		$('.wrapelement').each(function(){
			$(this).find('h1').css({'left':0,'right':0,'margin':0,'padding':0,'opacity':0});
		});
		//console.log("kill timeline");
		slide();
	});
	
	
	
	
})(jQuery);