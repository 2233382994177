import './bootstrap/alert';
import './bootstrap/button';
import './bootstrap/carousel';
import './bootstrap/collapse';
import './bootstrap/dropdown';
import './bootstrap/modal';
import './bootstrap/popover';
import './bootstrap/scrollspy';
import './bootstrap/tab';
import './bootstrap/toast';
import './bootstrap/tooltip';
import './bootstrap/util';
import './bootstrap/tools/sanitizer';

import './components/slider';
//import "@fortawesome/fontawesome-free/js/all";
//import 'blueimp-gallery/js/blueimp-gallery';
import 'superfish/dist/js/superfish';
import 'superfish/dist/js/hoverIntent';
import 'superfish/dist/js/supersubs';
import './components/swipper';
import './components/menu';
import './components/pushy';
import './components/modal';
//import './components/parallax';


$(document).ready(() => {
	
})