import Swiper from 'swiper';
$(document).ready(() => {
	var presentationSwiper = new Swiper('.swiper-container-presentation', {
		navigation: {
		//	nextEl: '.swiper-button-next',
		//	prevEl: '.swiper-button-prev',
			nextEl: '.fa-angle-left',
			prevEl: '.fa-angle-right',
		},
		slidesPerView:3,
		slidesPerColumn:2,
		observer: true,
		observeParents: true,
		setWrapperSize: true,
		spaceBetween:30,
      // init: false,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			1600: {
			slidesPerView: 3,
			spaceBetween: 30,
			},
			1400: {
			slidesPerView: 3,
			spaceBetween: 30,
			},
			1200: {
			slidesPerView: 3,
			spaceBetween: 30,
			},
			992: {
			slidesPerView: 2,
			spaceBetween: 30,
			},
			768: {
			slidesPerView: 2,
			spaceBetween: 30,
			},
			576: {
			slidesPerView: 1,
			spaceBetween: 30,
			}
		}
		
	});
	
})

$(document).ready(() => {
	var testimonialSwiper = new Swiper('.swiper-container-testimonial', {
		navigation: {
		//	nextEl: '.swiper-button-next',
		//	prevEl: '.swiper-button-prev',
			nextEl: '.fa-angle-left',
			prevEl: '.fa-angle-right',
		},
		slidesPerView:1,
		slidesPerColumn:1,
		observer: true,
		observeParents: true,
		setWrapperSize: true,
		spaceBetween:30,
      // init: false,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		breakpoints: {
			1600: {
			slidesPerView: 1,
			spaceBetween: 30,
			},
			1400: {
			slidesPerView: 1,
			spaceBetween: 30,
			},
			1200: {
			slidesPerView: 1,
			spaceBetween: 30,
			},
			992: {
			slidesPerView: 1,
			spaceBetween: 30,
			},
			768: {
			slidesPerView: 1,
			spaceBetween: 30,
			},
			576: {
			slidesPerView: 1,
			spaceBetween: 30,
			}
		}
		
	});
	
})

